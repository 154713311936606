export default [
  {
    title: 'Tableau de bord',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  // product
  {
    title: 'Produits',
    icon: 'DatabaseIcon',
    children: [
      {
        title: 'Liste des produits',
        route: 'products',
      },
      {
        title: 'Ajouter un produit',
        route: 'create-product',
      },
    ],
  },
  {
    title: 'Clients',
    icon: 'UserCheckIcon',
    children: [
      {
        title: 'Client',
        route: 'customers',
      },
      {
        title: 'Ajouter un client',
        route: 'create-customer',
      },
    ],
  },

  {
    title: 'Credits',
    icon: 'CheckIcon',
    children: [
      {
        title: 'credit',
        route: 'credits',
      },
      {
        title: 'Ajouter un credit',
        route: 'create-credit',
      },
    ],
  },

  {
    title: 'Achat',
    icon: 'ShoppingBagIcon',
    children: [

      {
        title: 'Commande Achat',
        route: 'purchase-orders',
      },
      {
        title: 'Ajouter Achat',
        route: 'create-purchase-order',
      },

    ],
  },
  {
    title: 'Bon de transfert',
    icon: 'ShoppingCartIcon',
    children: [
      {
        title: 'liste Bon de transfert',
        route: 'orders',
      },
      {
        title: 'Ajouter un Bon de transfert',
        route: 'create-order',
      },
    ],
  },

  {
    title: 'Bon de sortie',
    icon: 'TruckIcon',
    children: [
      {
        title: 'Liste des Bon de sortie',
        route: 'exit-vouchers',
      },
      {
        title: 'Ajouter un bon de sortie',
        route: 'create-exit-voucher',
      },
    ],
  },
  {
    title: 'Factures',
    icon: 'ClipboardIcon',
    children: [
      {
        title: 'Liste des factures',
        route: 'invoices',
      },
      {
        title: 'Ajouter une facture',
        route: 'create-invoice',
      },
      {
        title: 'Facture Declaré',
        route: 'declared-invoices',
      },
    ],
  },
  {
    title: 'Devis',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'List Devis',
        route: 'estimates',
      },
      {
        title: 'Créer un devis',
        route: 'create-estimate',
      },
    ],
  },

  {
    title: 'Machine Vendue',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'List Machine',
        route: 'machines',
      },
      {
        title: 'Ajouter Machine',
        route: 'create-machine',
      },
    ],
  },

  {
    title: 'Certificat de travail',
    icon: 'BookmarkIcon',
    route: 'work-certificates',
  },
  {
    title: 'Certificat de garantie',
    icon: 'AwardIcon',
    route: 'guarantee-certificates',
  },
  {
    title: 'Paramètre',
    icon: 'SettingsIcon',
    route: 'settings',
  },
]
